
//loads XURb foundation scripts;
$(document).foundation();

// Inits headroomJS
var topBar = document.getElementById('topbar'),
    headroom = new Headroom(topBar),
    viewportWidth = $(window).width();

headroom.init();

if (viewportWidth >= 1024) {
  // Init Stellar.JS
  $.stellar({
    horizontalOffset: 50,
    verticalOffset: 50
  });
}

if (viewportWidth >= 640) {
  // Inits WOW JS
  new WOW().init();
}

// Make a new social sharing button
new ShareButton({
  ui: {
    buttonText: 'Partilhar'
  },
  networks: {
    googlePlus: {
      enabled: true
    },
    twitter: {
      enabled: true
    },
    facebook: {
      enabled: true
    },
    pinterest: {
      enabled: true
    },
    reddit: {
      enabled: false
    },
    linkedin: {
      enabled: false
    },
    whatsapp: {
      enabled: false
    },
    email: {
      enabled: false
    }
  }
});

// Inits Animsition
$(document).ready(function() {
  $(".animsition").animsition({
    inClass: 'fade-in',
    outClass: 'fade-out',
    inDuration: 750,
    outDuration: 400,
    linkElement: '.animsition-link',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
    loading: false,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : false,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });
});


topbarBehaviour = {
  targetElement: $('#topbar'),
  hamburger: $('#hamburger'),
  mainContent: $('#container'),
  menuWrapper: $('#menu-wrapper'),
  isOpen: false,
  toggle: function () {
    if (this.isOpen) {
      this.targetElement.addClass('topbar-closed');
      this.targetElement.removeClass('topbar-open');
      this.hamburger.removeClass('open');
      this.mainContent.addClass('js-document-closed');
      this.mainContent.removeClass('js-document-opened');
      this.menuWrapper.addClass('menu-wrapper-closed');
      this.menuWrapper.removeClass('menu-wrapper-open');
      this.isOpen = false;
    } else {
      this.targetElement.addClass('topbar-open');
      this.targetElement.removeClass('topbar-closed');
      this.hamburger.addClass('open');
      this.mainContent.addClass('js-document-opened');
      this.mainContent.removeClass('js-document-closed');
      this.menuWrapper.addClass('menu-wrapper-open');
      this.menuWrapper.removeClass('menu-wrapper-closed');
      this.isOpen = true;
    }
  },
};

activBehaviour = {
  targetElement: $('section.actividades'),
  selector: $('section.actividades .selector'),
  content: $('section.actividades .content'),
  isActive: 2,
  switch: function (id) {
    if (id == this.isActive) {
      return;
    } else {
      this.selector.find('.actv-select[actv="'+this.isActive+'"]').toggleClass('active');
      this.selector.find('.actv-select[actv="'+id+'"]').toggleClass('active');
      this.content.find('.item[cid="'+this.isActive+'"]').toggleClass('active');
      this.content.find('.item[cid="'+id+'"]').toggleClass('active');
      this.isActive = id;
    }
  },
}

$('#hamburger').click(function() {
  topbarBehaviour.toggle();
});

$('section.actividades .selector .actv-select').each(function() {
  $(this).click(function() {
    activBehaviour.switch($(this).attr('actv'));
  });
});
